import React from 'react'
import './Home.scss'
import Header from '../components/Header'
import Card from '../components/Card'
import { useState } from 'react'



function Home() {

    // document.body.style.overflow="hidden";
    

    // const [isAnimationDone, setIsAnimationDone] = useState(false)

    // function loadedContent(){
    //     setTimeout(()=>{
    //         setIsAnimationDone(true)},2000)
    //     }
    
    // if(isAnimationDone){
    //     document.body.style.overflowY="scroll";
    // }

    const [activeTab,setActiveTab] = useState('all')

    


    return (
        <div>

            {/* <div className={isAnimationDone? "hidden":"overlay"}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div> */}


            <div className="main-content">
                <Header
                    title="Hannah"
                    subtitle="Wu"
                    body={["I design and code things that transform interesting ideas into reality.",`${String.fromCodePoint("0x1F646")} UX Engineer @UW HCDE`,`${String.fromCodePoint("0x1F4BB")} Prototyping Lover (2D/3D/code)`,`${String.fromCodePoint("0x1F596")} Proudly built this portfolio with React`]}
                />
                <div className="filters">
                    <button onClick={()=>{setActiveTab('all')}} className={activeTab === 'all' ? 'active':null}>All</button>
                    <button onClick={()=>{setActiveTab('ux')}} className={activeTab === 'ux' ? 'active':null}>Design</button>
                    <button onClick={()=>{setActiveTab('dev')}} className={activeTab === 'dev'? 'active':null}>Development</button>
                    </div>
                <div className="project-container">
                    <div className={activeTab === 'all' || activeTab === 'ux' ? "card-row":"hidden"}>
                        <Card
                            image={require('../images/mockup_pet.jpg')}
                            placeholderImg = {require('../images/mockup_pet_placeholder.jpg')}
                            title="Fluffy"
                            subtitle="Animal Adoption Website"
                            intro="Connecting adopters with their ideal pets"
                            backgroundColor="two"
                            link="/Pet"
                            // visibility={activeTab === 'all' || activeTab === 'ux' ? true:false}
                            tag={["UX research","UX design","web development"]}
                            />

                            <Card
                            image={require('../images/cover-seller-lg.jpg')}
                            placeholderImg = {require('../images/cover-seller-placeholder.jpg')}
                            title="PeerMarket"
                            subtitle="Web Application for P2P Selling"
                            intro="Help P2P sellers better manage their business"
                            backgroundColor="six"
                            link="/p2p-seller"
                            tag={["UX research","UX design"]}
                            />

                  
                        
                    </div>

                <div className={activeTab === 'all' || activeTab === 'ux' ? "card-row":"hidden"}>
                <Card
                            image={require('../images/n_cover_2.jpg')}
                            placeholderImg = {require('../images/n_cover_2_placeholder.jpg')}
                            title="Project Galene"
                            subtitle="Microplastic Research App and Sampler"
                            intro="A combination of mobile app and hardware kit aims at encouraging scuba divers to contribute to microplastic research"
                            backgroundColor="one"
                            link="/galene"
                            // visibility={activeTab === 'all' || activeTab === 'ux'? true:false}
                            tag={["UX research","UX design"]}
                        />    
                             <Card
                            image={require('../images/phone-cover.jpg')}
                            placeholderImg = {require('../images/phone-cover-placeholder.jpg')}
                            title="Project 3D"
                            subtitle="3D Illustrations"
                            intro="3D illustrations built from scratch with Blender"
                            backgroundColor="five"
                            link="/blender3d"
                            // visibility={activeTab === 'all' || activeTab === 'ux' ? true:false}
                            tag={["UX design"]}
                            />     
                       
                    </div>
                

                    {/* don't know how to have the animation as well as have a single item in a row */}

                    <div className={activeTab === 'all' ? "card-row":"hidden"}>
                    <Card
                            image={require('../images/n_cover_1.jpg')}
                            placeholderImg = {require('../images/n_cover_1_placeholder.jpg')}
                            title="InterArt"
                            subtitle="Interactive Art Community Web App"
                            intro="An interactive multimedia art community for designers and art lovers"
                            backgroundColor="four"
                            link="/interart"
                            //visibility={activeTab === 'all' || activeTab === 'ux' || activeTab === 'dev'? true:false}
                            tag={["UX research","UX design","web development"]}
                        />
                  
                       <Card
                            image={require('../images/cover3.jpg')}
                            placeholderImg = {require('../images/cover3_placeholder.jpg')}
                            title="Weather Widget"
                            subtitle="Weather Web App"
                            intro="Know current and future weather in your city at a glance "
                            backgroundColor="three"
                            link="/weather-widget"
                            //visibility={activeTab === 'all' || activeTab === 'dev'? true:false}
                            tag={["web development","UI design"]}
                        />

                    </div>

                    <div className={ activeTab === 'dev' ? "card-row":"hidden"}>
                    <Card
                            image={require('../images/mockup_pet.jpg')}
                            placeholderImg = {require('../images/mockup_pet_placeholder.jpg')}
                            title="Fluffy"
                            subtitle="Animal Adoption Website"
                            intro="Connecting adopters with their ideal pets"
                            backgroundColor="two"
                            link="/Pet"
                            // visibility={activeTab === 'all' || activeTab === 'ux' ? true:false}
                            tag={["UX research","UX design","web development"]}
                            />

                    <Card
                            image={require('../images/n_cover_1.jpg')}
                            placeholderImg = {require('../images/n_cover_1_placeholder.jpg')}
                            title="InterArt"
                            subtitle="Interactive Art Community Web App"
                            intro="An interactive multimedia art community for designers and art lovers"
                            backgroundColor="four"
                            link="/interart"
                            //visibility={activeTab === 'all' || activeTab === 'ux' || activeTab === 'dev'? true:false}
                            tag={["UX research","UX design","web development"]}
                        />
                        
                        </div>

                    <div className={ activeTab === 'dev' ? "card-row":"hidden"}>
                  
                         
                    <Card
                            image={require('../images/cover3.jpg')}
                            placeholderImg = {require('../images/cover3_placeholder.jpg')}
                            title="Weather Widget"
                            subtitle="Weather Web App"
                            intro="Know current and future weather in your city at a glance "
                            backgroundColor="three"
                            link="/weather-widget"
                            //visibility={activeTab === 'all' || activeTab === 'dev'? true:false}
                            tag={["web development","UI design"]}
                        />
                         {/* placeholder card */}
                         <Card
                            image={require('../images/n_cover_placeholder.png')}
                            backgroundColor="three"
                            
                            visibility="placeholder"
                           
                        />
                      
                       
                    </div>
                </div>
            </div>
        </div>


    )
}

export default Home